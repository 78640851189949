<template>
  <v-container fluid>
    <v-alert type="error" dense v-if="expired">
      {{ $t("__storebill_over_deadline_text") }}
    </v-alert>
    <v-card class="mx-auto" :loading="loading">
      <template slot="progress">
        <v-progress-linear
          color="primary darken-2"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{
          $t("__storebill_title") + " (" + billingID + ")"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="!loading">
        <table class="data_table">
          <tr>
            <th class="data_table__title">
              {{ $t("__storebill_period") }}
            </th>
            <td class="data_table__content">
              {{
                period !== "0年" && period !== "0year"
                  ? period
                  : periodStartDate + " ~ " + periodEndDate
              }}
            </td>
          </tr>
          <tr>
            <td colspan="2"><v-divider class="divider" /></td>
          </tr>
          <tr>
            <th class="data_table__title">
              {{ $t("__storebill_amount_detail") }}
            </th>
            <td class="data_table__content">
              <v-simple-table dense class="outlined_table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("__storebill_amount_regular_fee") }}
                      </th>
                      <th class="text-left">
                        {{ $t("__storebill_amount_transaction_fee") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ billingData.details["regular_fee"] | currency }}
                      </td>
                      <td>
                        {{ billingData.details["transaction_fee"] | currency }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
          <tr>
            <th class="data_table__title">
              {{ $t("__storebill_amount_total") }}
            </th>
            <td class="data_table__content">
              {{ billingData.amount | currency }}
            </td>
          </tr>
          <tr>
            <td colspan="2"><v-divider class="divider" /></td>
          </tr>
          <tr>
            <th class="data_table__title">
              {{ $t("__storebill_plan_name") }}
            </th>
            <td class="data_table__content">{{ planName }}</td>
          </tr>
          <tr>
            <th class="data_table__title">
              {{ $t("__storebill_deadline") }}
            </th>
            <td class="data_table__content">{{ deadline }}</td>
          </tr>
          <tr>
            <th class="data_table__title">
              {{ $t("__storebill_bill_status") }}
            </th>
            <td class="data_table__content">
              <v-simple-table dense class="outlined_table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("__storebill_status") }}
                      </th>
                      <th class="text-left">
                        {{ $t("__storebill_status_time") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ $t(`__storebill_status_${billingData.status}`) }}
                      </td>
                      <td>{{ statusTime }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";

export default {
  name: "Billing",
  computed: {
    ...mapGetters({
      billingID: "billing/billingID",
      billingData: "billing/data",
      planName: "billing/planName"
    })
  },
  data() {
    return {
      loading: false,
      deadline: "",
      expired: false,
      statusTime: "",
      period: "",
      periodStartDate: "",
      periodEndDate: ""
    };
  },
  methods: {
    formatData() {
      if (!this.billingData.month) {
        this.period =
          this.billingData.year + this.$t("__storebill_period_year");
      } else {
        this.period = this.billingData.year + "/" + this.billingData.month;
      }
      if (!this.billingData.year) {
        this.periodStartDate = format(
          new Date(this.billingData["start_time"]),
          "yyyy/MM/dd"
        );
        this.periodEndDate = format(
          new Date(this.billingData["end_time"]),
          "yyyy/MM/dd"
        );
      }
      this.deadline = format(new Date(this.billingData.deadline), "yyyy/MM/dd");
      this.statusTime = format(
        new Date(this.billingData.update_time),
        "yyyy/MM/dd"
      );
    },
    loadBilling() {
      this.loading = true;
      return this.$store
        .dispatch("billing/getBilling", this.$route.params.id)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  watch: {
    billingData: {
      deep: true,
      handler(val) {
        if (val) {
          this.formatData();
        }
      }
    },
    deadline: {
      immediate: true,
      handler(val) {
        if (val) {
          let today = format(new Date(), "T");
          let deadlineTimeStamp = format(new Date(val), "T");
          if (
            today > deadlineTimeStamp &&
            this.billingData.status === "unpaid"
          ) {
            this.expired = true;
          } else {
            this.expired = false;
          }
        }
      }
    }
  },
  created() {
    this.loadBilling();
  }
};
</script>
<style lang="scss">
.data_table {
  width: 100%;

  td {
    margin-block: 1em;
  }
  &__title {
    text-align: left;
    vertical-align: top;
    padding-right: 2em;
    white-space: nowrap;
  }
  &__content {
    width: 100%;
  }
}
.outlined_table {
  border: 1px solid #bdbdbd;
}
.divider {
  margin: 1em 0;
}
</style>
